@mixin clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin box {
  box-shadow: 5px 5px 16px 0 $dshadow;
}


@mixin nodebox {
  position: relative;
      text-align: center;


      .field--name-field-date {
        .field__label {
          font-weight: 300;
          font-size: $smaller;
          float: none;
          display: inline-block;
        }

        .field__items {
          font-weight: 700;
          font-size: $small;
          float: none;
          display: inline-block;
        }
      }

      .custom-title {
        font-size: $newsletter;
        margin-top: 20px;
        line-height: 1.5;
        @include breakpoint(550px) {
          font-size: 1rem;
          line-height: 1.6;
        }

        a {
          color: $white;
          text-decoration: none;
        }
      }

      .field--name-field-image {
        min-height: 555px;
        position: relative;
        overflow: hidden;

        a {
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            //opacity: 0.8;
            background-color: rgba($black, 0.85);
          }
        }

        img {
          min-height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          max-width: none;
          transform: translateX(-50%);
        }
      }

      .read-more {
        position: absolute;
        //bottom: 122px;
        bottom: 108px;
        left: 50%;
        transform: translateX(-50%);

        &.rmore.button {
          a {
            border: 1px solid $white;
            color: $white;

            &:after {
              @include sprite($sprite-read-more-white);
            }

            &:hover,
            &:focus {
              border: 1px solid $yellow;
              color: $black;

              &:after {
                @include sprite($sprite-read-more-black);
              }
            }
          }
        }
      }

      .group-all-info {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        padding: 64px 32px 32px 32px;
        color: $white;
        z-index: 10;
      }
}