// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-arrow-name: 'sprite_arrow';
$sprite-arrow-x: 466px;
$sprite-arrow-y: 354px;
$sprite-arrow-offset-x: -466px;
$sprite-arrow-offset-y: -354px;
$sprite-arrow-width: 18px;
$sprite-arrow-height: 12px;
$sprite-arrow-total-width: 497px;
$sprite-arrow-total-height: 473px;
$sprite-arrow-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-arrow: (466px, 354px, -466px, -354px, 18px, 12px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_arrow', );
$sprite-box-rectangles-white-name: 'sprite_box-rectangles-white';
$sprite-box-rectangles-white-x: 266px;
$sprite-box-rectangles-white-y: 186px;
$sprite-box-rectangles-white-offset-x: -266px;
$sprite-box-rectangles-white-offset-y: -186px;
$sprite-box-rectangles-white-width: 36px;
$sprite-box-rectangles-white-height: 36px;
$sprite-box-rectangles-white-total-width: 497px;
$sprite-box-rectangles-white-total-height: 473px;
$sprite-box-rectangles-white-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-box-rectangles-white: (266px, 186px, -266px, -186px, 36px, 36px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_box-rectangles-white', );
$sprite-box-rectangles-yellow-name: 'sprite_box-rectangles-yellow';
$sprite-box-rectangles-yellow-x: 226px;
$sprite-box-rectangles-yellow-y: 186px;
$sprite-box-rectangles-yellow-offset-x: -226px;
$sprite-box-rectangles-yellow-offset-y: -186px;
$sprite-box-rectangles-yellow-width: 38px;
$sprite-box-rectangles-yellow-height: 38px;
$sprite-box-rectangles-yellow-total-width: 497px;
$sprite-box-rectangles-yellow-total-height: 473px;
$sprite-box-rectangles-yellow-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-box-rectangles-yellow: (226px, 186px, -226px, -186px, 38px, 38px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_box-rectangles-yellow', );
$sprite-close-modal-name: 'sprite_close-modal';
$sprite-close-modal-x: 304px;
$sprite-close-modal-y: 226px;
$sprite-close-modal-offset-x: -304px;
$sprite-close-modal-offset-y: -226px;
$sprite-close-modal-width: 42px;
$sprite-close-modal-height: 40px;
$sprite-close-modal-total-width: 497px;
$sprite-close-modal-total-height: 473px;
$sprite-close-modal-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-close-modal: (304px, 226px, -304px, -226px, 42px, 40px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_close-modal', );
$sprite-dribble-name: 'sprite_dribble';
$sprite-dribble-x: 338px;
$sprite-dribble-y: 186px;
$sprite-dribble-offset-x: -338px;
$sprite-dribble-offset-y: -186px;
$sprite-dribble-width: 32px;
$sprite-dribble-height: 32px;
$sprite-dribble-total-width: 497px;
$sprite-dribble-total-height: 473px;
$sprite-dribble-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-dribble: (338px, 186px, -338px, -186px, 32px, 32px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_dribble', );
$sprite-e-sepia-cp-32-name: 'sprite_e-sepia-cp-32';
$sprite-e-sepia-cp-32-x: 388px;
$sprite-e-sepia-cp-32-y: 319px;
$sprite-e-sepia-cp-32-offset-x: -388px;
$sprite-e-sepia-cp-32-offset-y: -319px;
$sprite-e-sepia-cp-32-width: 92px;
$sprite-e-sepia-cp-32-height: 33px;
$sprite-e-sepia-cp-32-total-width: 497px;
$sprite-e-sepia-cp-32-total-height: 473px;
$sprite-e-sepia-cp-32-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-e-sepia-cp-32: (388px, 319px, -388px, -319px, 92px, 33px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_e-sepia-cp-32', );
$sprite-facebook-name: 'sprite_facebook';
$sprite-facebook-x: 348px;
$sprite-facebook-y: 226px;
$sprite-facebook-offset-x: -348px;
$sprite-facebook-offset-y: -226px;
$sprite-facebook-width: 32px;
$sprite-facebook-height: 32px;
$sprite-facebook-total-width: 497px;
$sprite-facebook-total-height: 473px;
$sprite-facebook-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-facebook: (348px, 226px, -348px, -226px, 32px, 32px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_facebook', );
$sprite-linkedin-name: 'sprite_linkedin';
$sprite-linkedin-x: 304px;
$sprite-linkedin-y: 186px;
$sprite-linkedin-offset-x: -304px;
$sprite-linkedin-offset-y: -186px;
$sprite-linkedin-width: 32px;
$sprite-linkedin-height: 32px;
$sprite-linkedin-total-width: 497px;
$sprite-linkedin-total-height: 473px;
$sprite-linkedin-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-linkedin: (304px, 186px, -304px, -186px, 32px, 32px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_linkedin', );
$sprite-next-name: 'sprite_next';
$sprite-next-x: 388px;
$sprite-next-y: 368px;
$sprite-next-offset-x: -388px;
$sprite-next-offset-y: -368px;
$sprite-next-width: 19px;
$sprite-next-height: 6px;
$sprite-next-total-width: 497px;
$sprite-next-total-height: 473px;
$sprite-next-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-next: (388px, 368px, -388px, -368px, 19px, 6px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_next', );
$sprite-read-more-black-name: 'sprite_read-more-black';
$sprite-read-more-black-x: 388px;
$sprite-read-more-black-y: 354px;
$sprite-read-more-black-offset-x: -388px;
$sprite-read-more-black-offset-y: -354px;
$sprite-read-more-black-width: 37px;
$sprite-read-more-black-height: 12px;
$sprite-read-more-black-total-width: 497px;
$sprite-read-more-black-total-height: 473px;
$sprite-read-more-black-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-read-more-black: (388px, 354px, -388px, -354px, 37px, 12px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_read-more-black', );
$sprite-read-more-white-name: 'sprite_read-more-white';
$sprite-read-more-white-x: 427px;
$sprite-read-more-white-y: 354px;
$sprite-read-more-white-offset-x: -427px;
$sprite-read-more-white-offset-y: -354px;
$sprite-read-more-white-width: 37px;
$sprite-read-more-white-height: 12px;
$sprite-read-more-white-total-width: 497px;
$sprite-read-more-white-total-height: 473px;
$sprite-read-more-white-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-read-more-white: (427px, 354px, -427px, -354px, 37px, 12px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_read-more-white', );
$sprite-read-more-yellow-name: 'sprite_read-more-yellow';
$sprite-read-more-yellow-x: 337px;
$sprite-read-more-yellow-y: 268px;
$sprite-read-more-yellow-offset-x: -337px;
$sprite-read-more-yellow-offset-y: -268px;
$sprite-read-more-yellow-width: 37px;
$sprite-read-more-yellow-height: 12px;
$sprite-read-more-yellow-total-width: 497px;
$sprite-read-more-yellow-total-height: 473px;
$sprite-read-more-yellow-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-read-more-yellow: (337px, 268px, -337px, -268px, 37px, 12px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_read-more-yellow', );
$sprite-theuxprodigy-name: 'sprite_theuxprodigy';
$sprite-theuxprodigy-x: 226px;
$sprite-theuxprodigy-y: 162px;
$sprite-theuxprodigy-offset-x: -226px;
$sprite-theuxprodigy-offset-y: -162px;
$sprite-theuxprodigy-width: 140px;
$sprite-theuxprodigy-height: 22px;
$sprite-theuxprodigy-total-width: 497px;
$sprite-theuxprodigy-total-height: 473px;
$sprite-theuxprodigy-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-theuxprodigy: (226px, 162px, -226px, -162px, 140px, 22px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_theuxprodigy', );
$sprite-tickbox-mobile-name: 'sprite_tickbox-mobile';
$sprite-tickbox-mobile-x: 226px;
$sprite-tickbox-mobile-y: 0px;
$sprite-tickbox-mobile-offset-x: -226px;
$sprite-tickbox-mobile-offset-y: 0px;
$sprite-tickbox-mobile-width: 160px;
$sprite-tickbox-mobile-height: 160px;
$sprite-tickbox-mobile-total-width: 497px;
$sprite-tickbox-mobile-total-height: 473px;
$sprite-tickbox-mobile-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-tickbox-mobile: (226px, 0px, -226px, 0px, 160px, 160px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_tickbox-mobile', );
$sprite-tickbox-name: 'sprite_tickbox';
$sprite-tickbox-x: 0px;
$sprite-tickbox-y: 0px;
$sprite-tickbox-offset-x: 0px;
$sprite-tickbox-offset-y: 0px;
$sprite-tickbox-width: 224px;
$sprite-tickbox-height: 224px;
$sprite-tickbox-total-width: 497px;
$sprite-tickbox-total-height: 473px;
$sprite-tickbox-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-tickbox: (0px, 0px, 0px, 0px, 224px, 224px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_tickbox', );
$sprite-twitter-name: 'sprite_twitter';
$sprite-twitter-x: 304px;
$sprite-twitter-y: 268px;
$sprite-twitter-offset-x: -304px;
$sprite-twitter-offset-y: -268px;
$sprite-twitter-width: 31px;
$sprite-twitter-height: 32px;
$sprite-twitter-total-width: 497px;
$sprite-twitter-total-height: 473px;
$sprite-twitter-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-twitter: (304px, 268px, -304px, -268px, 31px, 32px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_twitter', );
$sprite-value-1-mobile-name: 'sprite_value-1-mobile';
$sprite-value-1-mobile-x: 388px;
$sprite-value-1-mobile-y: 0px;
$sprite-value-1-mobile-offset-x: -388px;
$sprite-value-1-mobile-offset-y: 0px;
$sprite-value-1-mobile-width: 109px;
$sprite-value-1-mobile-height: 108px;
$sprite-value-1-mobile-total-width: 497px;
$sprite-value-1-mobile-total-height: 473px;
$sprite-value-1-mobile-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-value-1-mobile: (388px, 0px, -388px, 0px, 109px, 108px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_value-1-mobile', );
$sprite-value-2-mobile-name: 'sprite_value-2-mobile';
$sprite-value-2-mobile-x: 109px;
$sprite-value-2-mobile-y: 378px;
$sprite-value-2-mobile-offset-x: -109px;
$sprite-value-2-mobile-offset-y: -378px;
$sprite-value-2-mobile-width: 85px;
$sprite-value-2-mobile-height: 82px;
$sprite-value-2-mobile-total-width: 497px;
$sprite-value-2-mobile-total-height: 473px;
$sprite-value-2-mobile-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-value-2-mobile: (109px, 378px, -109px, -378px, 85px, 82px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_value-2-mobile', );
$sprite-value-3-mobile-name: 'sprite_value-3-mobile';
$sprite-value-3-mobile-x: 0px;
$sprite-value-3-mobile-y: 378px;
$sprite-value-3-mobile-offset-x: 0px;
$sprite-value-3-mobile-offset-y: -378px;
$sprite-value-3-mobile-width: 107px;
$sprite-value-3-mobile-height: 95px;
$sprite-value-3-mobile-total-width: 497px;
$sprite-value-3-mobile-total-height: 473px;
$sprite-value-3-mobile-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-value-3-mobile: (0px, 378px, 0px, -378px, 107px, 95px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_value-3-mobile', );
$sprite-value-4-mobile-name: 'sprite_value-4-mobile';
$sprite-value-4-mobile-x: 388px;
$sprite-value-4-mobile-y: 214px;
$sprite-value-4-mobile-offset-x: -388px;
$sprite-value-4-mobile-offset-y: -214px;
$sprite-value-4-mobile-width: 103px;
$sprite-value-4-mobile-height: 103px;
$sprite-value-4-mobile-total-width: 497px;
$sprite-value-4-mobile-total-height: 473px;
$sprite-value-4-mobile-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-value-4-mobile: (388px, 214px, -388px, -214px, 103px, 103px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_value-4-mobile', );
$sprite-value-icon-1-name: 'sprite_value-icon-1';
$sprite-value-icon-1-x: 388px;
$sprite-value-icon-1-y: 110px;
$sprite-value-icon-1-offset-x: -388px;
$sprite-value-icon-1-offset-y: -110px;
$sprite-value-icon-1-width: 105px;
$sprite-value-icon-1-height: 102px;
$sprite-value-icon-1-total-width: 497px;
$sprite-value-icon-1-total-height: 473px;
$sprite-value-icon-1-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-value-icon-1: (388px, 110px, -388px, -110px, 105px, 102px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_value-icon-1', );
$sprite-value-icon-2-name: 'sprite_value-icon-2';
$sprite-value-icon-2-x: 152px;
$sprite-value-icon-2-y: 226px;
$sprite-value-icon-2-offset-x: -152px;
$sprite-value-icon-2-offset-y: -226px;
$sprite-value-icon-2-width: 150px;
$sprite-value-icon-2-height: 133px;
$sprite-value-icon-2-total-width: 497px;
$sprite-value-icon-2-total-height: 473px;
$sprite-value-icon-2-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-value-icon-2: (152px, 226px, -152px, -226px, 150px, 133px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_value-icon-2', );
$sprite-value-icon-3-name: 'sprite_value-icon-3';
$sprite-value-icon-3-x: 0px;
$sprite-value-icon-3-y: 226px;
$sprite-value-icon-3-offset-x: 0px;
$sprite-value-icon-3-offset-y: -226px;
$sprite-value-icon-3-width: 150px;
$sprite-value-icon-3-height: 150px;
$sprite-value-icon-3-total-width: 497px;
$sprite-value-icon-3-total-height: 473px;
$sprite-value-icon-3-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-value-icon-3: (0px, 226px, 0px, -226px, 150px, 150px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_value-icon-3', );
$sprite-visit-website-name: 'sprite_visit-website';
$sprite-visit-website-x: 368px;
$sprite-visit-website-y: 162px;
$sprite-visit-website-offset-x: -368px;
$sprite-visit-website-offset-y: -162px;
$sprite-visit-website-width: 18px;
$sprite-visit-website-height: 15px;
$sprite-visit-website-total-width: 497px;
$sprite-visit-website-total-height: 473px;
$sprite-visit-website-image: '../images/sprites/build/sprites.png?v=1593611976130';
$sprite-visit-website: (368px, 162px, -368px, -162px, 18px, 15px, 497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', 'sprite_visit-website', );
$spritesheet-width: 497px;
$spritesheet-height: 473px;
$spritesheet-image: '../images/sprites/build/sprites.png?v=1593611976130';
$spritesheet-sprites: ($sprite-arrow, $sprite-box-rectangles-white, $sprite-box-rectangles-yellow, $sprite-close-modal, $sprite-dribble, $sprite-e-sepia-cp-32, $sprite-facebook, $sprite-linkedin, $sprite-next, $sprite-read-more-black, $sprite-read-more-white, $sprite-read-more-yellow, $sprite-theuxprodigy, $sprite-tickbox-mobile, $sprite-tickbox, $sprite-twitter, $sprite-value-1-mobile, $sprite-value-2-mobile, $sprite-value-3-mobile, $sprite-value-4-mobile, $sprite-value-icon-1, $sprite-value-icon-2, $sprite-value-icon-3, $sprite-visit-website, );
$spritesheet: (497px, 473px, '../images/sprites/build/sprites.png?v=1593611976130', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
