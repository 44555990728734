@import "susy";
@import 'plugins/svg-grid';
@import "breakpoint";

@import "abstractions/_mixins.scss";
@import "variables/_sprites.scss";
@import "variables/_grid.scss";
@import "variables/_typography.scss";
@import "variables/_colors.scss";


$desktop: 1600px;
$desktop-smedium: 1080px;
$metrics: 1450px;
$laptop: 1366px;
$desktop-smaller: 1200px;
$desktop-med: 1260px;
$tablet: 1024px;
$sub-tablet: 930px;
$sub-tablet-small: 800px;
$mega-mobile: 680px;
$sub-mobile: 610px;
$mobile-sm: 560px;
$mobile: 500px;
$mobile-standard: 360px;
$mobile-small: 320px;

$unipadding: 20px;

@include breakpoint($desktop) {

  // paddings left stuff
  .l-nots,
  .l-header,
  .l-main,
  .l-footer,
  .l-content-2,
  .drup-msgs {
    margin-left: $unipadding;
    margin-right: $unipadding;
  }

  // .block--views-training-block-3 {
  //   .node__content {
  //     margin-left: $unipadding;
  //     margin-right: $unipadding;
  //   }
  // }

  .slicknav_menu {
    margin: 0 -$unipadding;
    padding: 0 $unipadding;
  }

  .grid-wrapper {
    .custom-title-box {
      left: $unipadding;
    }
  }

  .view-Companies {
    // width: span(9);
    // margin: 0 auto;

    .views-row {
      margin-right: gutter(of 9);
      float: left;
      width: span(3 of 9);
      text-align: center;

      &:nth-child(5n) {
        margin-right: 0;
        margin-bottom: 0;
      }

      &:nth-child(5n+1) {
        clear: none;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
        margin-bottom: gutter();
      }

      &:nth-child(3n+1) {
        clear: both;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include breakpoint($desktop-smedium) {
  // .block--system-main-menu li a {
  //   padding: 28px 30px;
  // }

  .activew-circle {
    right: 16px;
  }
}

@include breakpoint($metrics) {
  .metrics-wrapper {
    .metrics-animate,
    .metrics-extra {
      font-size: $giga * 0.78;
    }
  }

  .node--trainer--teaser {
    .node__content {
      height: 480px;
    }

    .field--name-field-image .field__item {
      height: 480px;
      line-height: 480px;
    }
  }
}

@include breakpoint($laptop) {

  .pgwloaderModal {
    img {
      max-width: 160px;
    }
  }

  .l-branding {
    max-height: 77px;
  }

  .block--gtweaks-front-main-banner {
    .subtitle {
      margin-bottom: 70px;
    }
  }

  .block--gtweaks-what-we-do {
    .view-content .box-image {
      width: 148px;
      height: 148px;
    }
  }

  .node--page--teaser {
    .button a {
      // padding: 13px;
      // min-width: 200px;
      padding: 20px;
      min-width: 210px;
    }  
  }
  .button a {
    padding: 13px;
    min-width: 200px;
  }

  .l-page {
    padding-top: 102px;
  }

  .notbar-removed {
    .l-page {
      padding-top: 79px;
    }
  }

  .logged-in .l-page {
    padding-top: 73px;
  }

  .block--gtweaks-front-main-banner {
    .block__content {
      max-height: calc(100vh - 102px);
    }
  }

  html {
    font-size: 16px;
    line-height: 1.5;
  }

  .node--trainer--teaser {
    .field--name-field-image {
      .field__item img {
        vertical-align: top;
      }
    }
  }

  .block--gtweaks-our-values {
    .view-content .value-item {
      font-size: $newsletter;

      .value-wrap {
          &::before {
            right: 20px;
            top: 0;
          }
      }
    }
  }

  .view-navigation-boxes {
    .views-row {
      width: span(6);
      margin-right: gutter();

      &.custom-title-box {
        height: 376px;
      }

      &:nth-child(2n) {
        margin-right: 0;
        margin-bottom: gutter();
      }

      &:nth-child(2n+1) {
        clear: both;
      }

      &:last-child {
        margin-right: 0;
        float: right;
      }
    }
  }

  .block--views-training-block-3 {
    .group-wrapper {
      min-height: 596px;
    }
  }

  .block {
    &--gtweaks-why-ux {

      padding-top: 40px;

     .box {
         @include box;
         width: span(4 of 8);
         margin-right: gutter(of 8);

        &:nth-child(3n) {
          margin-right: gutter(of 9);
          margin-bottom: 0;
        }

        &:nth-child(3n+1) {
          clear: none;
        }

        &:nth-child(2n) {
          margin-right: 0;
          margin-bottom: gutter(of 9);
        }

        &:nth-child(2n+1) {
          clear: both;
        }
      }

    }
  }
}

@include breakpoint($desktop-med) {
  .block--gtweaks-our-values {
    .view-content {
      .value-item h2 {
        font-size: $newsletter;
      }
    }
  }
  // #block-system-main-menu {
  //   display: none;
  // }
  // .slicknav_menu {
  //   display: block;
  //   text-align: center;
  // }
}

@include breakpoint($desktop-smaller) {

  .block--gtweaks-our-values .view-content {
    .value-item-2,
    .value-item-4 {
      br {
        display: none;
      }
    }
  }

  .node--webinars--full,
  .node--in-house-training--full,
  .node--public-workshops--full {
    div.field--name-field-map {
      margin-bottom: 100px;

      .map-wrap {
        &-fields {
          padding: 25px 0;
            width: span(4 of 6);
          //  margin: 0 auto;

          .field--name-field-location {
            width: 100%;
            margin-right: 0;
            float: none;

            .field__label {
              top: 2px;
            }
          }

          .visit-website-pw {
            float: none;
            width: 100%;
            top: 0;
          }
        }
      }
    }
  }

  .node--public-workshops--minimal {
    .group-info .field {
      float: none;
    }
  }

  .node--public-workshops--box .group-all-info {
    padding: 30px 15px 15px 15px;
  }
}

// gota
@include breakpoint($tablet) {

  // new variable values

  $gdelimit: 90px;
  $scale_fonts: 0.726;

  // typography
  $smallest: 0.66666rem; // 12px
  $noti: 0.72222rem; //13px
  $smaller: 0.77777rem; // 14px
  $small: 0.88888rem; // 16px
  $normal: 1rem; // 18px
  $slight: 1.11111rem; // 20px
  $ptext2: 1.22222rem; // 22px
  $ptext: 1.33333rem; // 24px
  $newsletter: 1.38888rem; // 25px
  $box: 1.55555rem; //28px
  $larger: 1.66666rem; //30px
  $largest: 1.77777rem; // 32px
  $pwork: 1.94444rem * $scale_fonts; // 35px
  $super: 2.5rem * $scale_fonts; //45px
  $kelvin: 2.5rem * $scale_fonts; // 45px
  $mega: 2.77777rem * $scale_fonts; // 50px
  $giga: 5.55555rem * $scale_fonts; // 100px

  // end typography

  #block-system-main-menu {
    display: none;
  }

  // gdl gdlimits
  .block--views-case-studies-block {
    margin-top: $gdelimit;
    margin-bottom: $gdelimit;
  }

  .block--gtweaks-what-we-do {
    padding-bottom: $gdelimit;
    margin-bottom: 0;
  }

  .view-testimonials {
    padding: $gdelimit 0;
  }

  .user-login {
    margin-bottom: $gdelimit / 2;
  }

  .block--views-companies-block {
    margin-bottom: $gdelimit;
  }

  .block {

    &--gtweaks {
      margin-bottom: $gdelimit;
    }

    &--gtweaks-public-workshops-metrics {
      margin-top: 80px;
      padding-top: 80px;
      margin-bottom: 80px;
    }

    &--views-team-block {
      margin-bottom: $gdelimit;
    }
  }

  .view-case-studies {
    &.view-display-id-block_1 {
      .views-row {
        margin-bottom: $gdelimit / 2;
        padding-bottom: $gdelimit / 2;
      }

      margin-bottom: $gdelimit;
    }
  }

  .view-training {
    &.view-display-id-block,
    &.view-display-id-block_1 {

      .views-row {
        margin-bottom: $gdelimit;
      }
    }

    &.view-display-id-block_6,
    &.view-display-id-block {
      .view-empty {
        margin-bottom: $gdelimit;
      }
    }

    &.view-display-id-block_4 {
      margin-bottom: $gdelimit;
    }
  }

  .node {
    &--full {

      // reset field collection values
      .field-collection-view {
        margin-bottom: $gdelimit;
      }

      &.nnode-31 {
        .field--name-field-collection-advanced-text {
          .field-collection-view-final {
            margin-bottom: $gdelimit;
          }
        }
      }

      &.nnode-32 {
        .field-collection-view {
          .field-collection-item-field-collection-advanced-text {
            margin-bottom: $gdelimit / 2;
            padding-bottom: $gdelimit / 2;
          }

          &-final {
            .field-collection-item-field-collection-advanced-text {
              margin-bottom: $gdelimit;
            }
          }
        }
      }

      &.nnode-33 {
        .field--name-body {
          margin-left: 0;
          width: 100%;
          margin-bottom: 80px;
        }
      }
    }
    &--public-workshops {

      &--full {

        .field--name-field-intro-text {
          margin-bottom: $gdelimit / 2;
        }

        .field--name-field-map {
          margin: $gdelimit / 2 0;
        }

        .field--name-field-body-4 {
          margin-bottom: $gdelimit / 2;
        }

        .field--name-field-entity-ref-trainer {

          > .field__items {
            > .field__item {
              margin-bottom: $gdelimit / 2;
            }
          }
        }

        .field--name-body,
        .field--name-field-body-2,
        .field--name-field-body-3 {
          margin-bottom: $gdelimit / 2;
          padding-bottom: $gdelimit / 2;
        }
      }

      &--teaser {
        @include clearfix;

        .custom-title {
          color: $white;
          font-size: $pwork;
        }

        .read-more {
          clear: both;
          padding-top: 60px;
        }

        .field--name-field-image {
          min-height: 555px;
          position: relative;
          overflow: hidden;

          img {
            min-height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            max-width: none;
            transform: translateX(-50%);
          }
        }

        .group-image-wrapper {
          text-align: center;
          position: relative;

          .custom-title {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            line-height: 1.4;
            z-index: 10;

            a {
              color: $white;
              text-decoration: none;
            }

            .title-grid-wrapper {
              width: span(5 of 9);
              margin: 0 auto;
            }
          }
        }

        .group-left-wrapper {
          width: span(6 of 9);
          float: left;
          margin-right: gutter(of 9);
        }

        .field--name-field-intro-text {
          font-size: $ptext2;
          color: $gray;
          font-weight: 400;
        }

        .read-more.rmore a {
          width: 214px;
        }

        .group-right-wrapper {
          width: span(2 of 9);
          margin-left: span(1 wide of 9);
          float: left;
          text-align: right;
          padding-top: 40px;

          .field--type-number-integer,
          .field--type-number-integer {
            margin-bottom: 25px;
          }

        }

        .group-info {
          @include clearfix;
          margin: 30px 0 30px;

          .field {
            float: left;
            margin-right: 20px;

            &__label {
              font-weight: 300;
              font-size: $smaller;
              position: relative;
              top: 2px;
            }

            &__items {
              font-weight: 700;
              font-size: $small;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    &--case-studies {

      &--full {

        .field--type-text-long,
        .field--type-text-with-summary {
          margin-bottom: $gdelimit / 2;
          padding-bottom: $gdelimit / 2;

          &:last-child {
            margin-bottom: $gdelimit;
          }
        }
      }

      &--teaser {

        .group-right-wrapper {
          width: span(6 of 9);
          //   margin-left: span(1 of 9) + gutter(of 9);
          margin-left: span(1 wide of 9);
          float: left;
        }

        .group-left-wrapper-cs {
          width: span(2 of 9);
          margin-right: gutter(of 9);
          float: left;
        }
      }

      &--minimal {
        @include clearfix;

        .group-right-wrapper {
          width: span(6 of 9);
          // margin-left: span(1 of 9) + gutter(of 9);
          margin-left: span(1 wide of 9);
          float: left;
        }

        .group-left-wrapper {
          width: span(2 of 9);
          margin-right: gutter(of 9);
          float: left;
        }
      }
    }
  }

  .field_delimiter {
    margin-bottom: $gdelimit / 2;
    padding-bottom: $gdelimit / 2;
  }

  // fix nav...
  #ux-design-section,
  #ux-evaluation-section,
  #ux-research-section {
    &:before {
      margin-top: -$gdelimit / 2;
      height: $gdelimit / 2;
    }
  }

  // end gdlimits

  // font sizes
  .price-bold {
    font-size: $slight;
  }

  .notifications {
    &-expandit {
      font-size: $noti;
    }
  }

  .block--views-case-studies-block {
    .view-content {
      width: span(10);
      margin: 0 auto;
      float: none;
    }
  }

  .block--gtweaks-social-media {
    margin-bottom: 0;
  }

  .view-case-studies {
    &.view-display-id-block {

      .vertical-number {
        //   position: relative;
        left: 0;
      }

      .views-row {
        margin-bottom: 190px;
        @include clearfix;
      }

      .views-row-last {
        margin-bottom: 0;
      }

      .views-row-even {
        .group-right-wrapper {
          width: 100%;
          float: none;
          margin-left: 0; // override code for the page of case studies
        }

        .group-left-wrapper-cs {
          float: none;
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .block--gtweaks-node-image-top,
  .block--gtweaks-node-image-bottom {
    margin-bottom: 180px;
  }

  // gota
  .view-case-studies.view-display-id-block {
    .views-row {
      margin-bottom: 80px;
    }
  }

  .block--views-case-studies-block-1 {
    .node--case-studies--minimal {
      width: span(10);
      margin-right: auto;
      margin-left: auto;
    }
  }

/*  .button a {
    padding: 13px;
    min-width: 200px;
  //  min-width: 214px;
  }*/

  .node--case-studies--full {

    > .node__content {
      padding-top: 60px;
    }

    .field--name-field-image {
      margin-bottom: 60px;
      width: 100%;
      margin-left: 0;
    }

    div.node__content {
      width: 100%;
    }

    .group-meta {
      width: span(8);
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 40px;
    }

    .field--type-text-long,
    .field--type-text-with-summary {
      width: span(10);
      margin: 0 auto;
      padding-bottom: 60px;
      margin-bottom: 60px;

      .field__label {
        width: 100%;
        margin-right: 0;
        float: none;
        margin-bottom: 20px;
      }

      .field__items {
        width: 100%;
        float: none;
        margin-left: 0;

        img {
          margin: 60px auto 0;
        }
      }
    }

    .field--name-field-body-5 {
      font-size: $ptext2;
    }
  }

  .node--full.nnode-7 .field--name-body {
    width: 100%;
    margin-left: 0;
    margin-bottom: 80px;
  }

  .view-case-studies.view-display-id-block_1 {
    .vertical-number {
      left: 0;
    }

    .view-content {
      padding-top: 40px;
      width: 100%;
      margin: 0 auto;
      float: none;
    }
  }

  .block--views-team-block {
    .vertical-number {
      padding-left: 0;
    }
  }

  .block--gtweaks-our-values {
    .view-content {
      padding-top: 80px;
      width: 100%;
      margin: 0 auto;
      float: none;

      .value-item {
        width: span(6);

        &:nth-child(2n) {
          margin-right: 0;
          margin-bottom: gutter();
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:nth-child(2n+1) {
          clear: both;
        }
      }
    }
  }

  .block--gtweaks-our-values {
    .vertical-number {
      padding-left: 0;
    }
  }

  .block--gtweaks-our-mantra {
    width: 100%;
    margin: 0 auto $gdelimit;
    margin-top: 80px;
    float: none;

    .mantra-image {
      height: 360px;
    }
  }

  .slicknav_menu {
    .slicknav_menutxt {
      right: 68px;
    }
  }

  // main node rule to allign everything to center
  .node--full {

    .field-collection-container .vertical-number {
      padding-left: 0;
    }

    .node__content {
      width: span(10);
      margin: 0 auto;
    }

    .field--name-field-collection-advanced-text .field-collection-view {

      .field--name-field-collection-body {
        img {
          margin: 80px auto;
        }
      }

      .field--name-field-collection-title {
        float: none;
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
        margin-left: 0;
      }

      .field--name-field-collection-body {
        width: 100%;
        float: none;
        margin-left: 0;
        font-size: $ptext2;
        line-height: 1.65;
        @include breakpoint(610px) {
          font-size: $normal;
        }
      }
    }

    &.nnode-32 .field-collection-view {
      .field-collection-item-field-collection-advanced-text {
        width: 100%;
        margin: 0 auto 45px;
        float: none;

        .field--name-field-collection-title,
        .field--name-field-collection-body {
          width: 100%;
          margin-right: 0;
          margin-left: 0;
        }

        .field--name-field-methods-techniques {
          width: 100%;
          margin-left: 0;
          padding-top: 40px;
        }
      }
    }

    &.nnode-33 .field--name-field-collection-advanced-text {
      width: 100%;
      margin-left: 0;
    }

  }

  .block--views-navigation-boxes-block {
    margin: 0 auto;
    width: span(10);
    padding: 60px 0;
  }

  .custom-title-box {
    height: 280px;
    width: 280px;
    min-width: 0;
    padding: 36px 24px;

    .title-text {
      font-size: $newsletter;
    }

    &:after {
      right: 24px;
      bottom: 24px;
    }

    .number {
      margin-bottom: 22px;
      width: 54px;
      height: 35px;
    }
  }

  .block--gtweaks-header-lets-talk.block a {
  //  padding: 15px 20px;
    padding: 15px 13px;
    width: 90px;
  }

  .site-logo {
    margin: 15px 0;
  }

  .l-header {
    height: 51px;

    .slicknav_menutxt {
      height: 51px;
    }
    // height: 50px;

    .block--gtweaks-header-lets-talk.block a {
      height: 51px;
    }
  }

  .l-branding {
    max-height: 51px;
    // max-height: 50px;
    min-width: 130px;
  }

  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form {
    input[type="text"] {
      font-size: 16px;
    }
  }

  .nnode-1 {

    .webform-call-us {
      .light-text {
        font-size: $smallest;
      }
    }

    .field--name-body {
      font-size: $small;
    }
  }

  .block {
    &--gtweaks-our-mantra {
      .group-wrapper {
        h2 {
          font-size: $box;
        }

        .group-right-wrapper {

          .large-title {
            font-size: $kelvin;
            line-height: 1.1;
          }

          .small-desc {
            font-size: $smallest;
          }
        }
      }
    }

    &--gtweaks-our-values {

      h2 {
        font-size: $box;
      }
    }

    &--gtweaks-header-lets-talk.block {

      a {
        font-size: $small;
      }

    }

    &--gtweaks-copyright.block {
      font-size: $smaller;
    }

    &--gtweaks-social-media {
      font-size: $smaller;
    }

    &--gtweaks-footer-lets-talk {

      .newsletter-intro {
        font-size: $newsletter;
        line-height: 1.35;
      }
    }

    &--views-training-block-3 {
      .group-wrapper {
        h2 {
          font-size: $larger;
        }
      }
    }

    &--gtweaks-what-we-do {
      @include clearfix;

      .view-content {
        .box {
          h2 {
            font-size: $box;
          }
        }
      }
    }

    &--gtweaks-why-ux {
      .block-title {
        font-size: $super;
      }
    }

    &--gtweaks-front-main-banner {
      h1 {
        font-size: $mega;
        line-height: 1.3;
      }
    }

  }

  .view-testimonials {

    .custom-title {
      font-size: $smaller;
    }

    .field--name-field-capacity {
      font-size: $smallest;
    }

    .field--name-body {
      font-size: $small;
      line-height: 1.8;
    }
  }

  .block--system-main-menu {
    li {

      a {
        font-size: $small;
      }
    }
  }

  .view-training {

    &.view-display-id-block_6,
    &.view-display-id-block {
      .view-empty {

        .grid-wrapper-inner {

          .custom-title {
            line-height: 1.4;
          }

          .empty-desc {
            font-size: $slight;
          }
        }

        .grid-wrapper {
          width: span(5 of 9);
          margin: 0 auto;
        //  min-height: 555px;
        }

        .custom-title {
          font-size: $pwork;
        }
      }
    }

    &.view-display-id-block_4 {

      .group-wrapper {
        h2 {
          font-size: $larger;
        }
      }
    }
  }

  .full-size-img {
    &-node {
      height: 360px;
      min-height: 0;
    }
  }

  .node {

    &--webform {
      .custom-title {
        font-size: $largest;
      }
    }

    &--advanced-page,
    &--page {
      &--full {
        .field--name-body {
          font-size: $ptext2;
          line-height: 1.65;
        }
      }
    }

    &--full {
      .field--name-field-collection-advanced-text {
        .field-collection-view {
          .field--name-field-collection-title {
            font-size: $box;
            line-height: 1.6;
          }
        }
      }

      .field--name-field-methods-techniques {
        .field__label {
          font-size: $slight;
        }

        .field-collection-view {
          .field--name-field-collection-title {
            font-size: $small;
          }
        }

        .field--name-field-collection-body,
        .field--name-field-body {
          line-height: 1.65;
          font-size: $small;
        }
      }
    }

    &--trainer {
      &--teaser {
        .custom-title {
          font-size: $box;
        }

        .group-right-wrapper {
          font-size: $small;
        }
      }

      &--minimal {

        .group-image {

          .custom-title {
            font-size: $small;
          }

          .field--name-field-capacity {
            font-size: $smaller;
          }
        }
      }
    }

    &--case-studies {

      &--full {
        .field--name-field-body-5 {
          //   font-size: $newsletter;
        }

        .custom-title,
        .field--name-field-subtitle {
          line-height: 1.2;
          font-size: $kelvin;
        }

        .case-study-title {
          font-size: $slight;
        }

        .field--type-text-long,
        .field--type-text-with-summary {

          .field__label {
            font-size: $box;
          }
        }
      }

      &--teaser {

        .custom-title {
          font-size: $larger;
        }
      }
    }

    &--in-house-training {
      &--teaser {
        .custom-title {
          font-size: $pwork;
        }

        .group-info {
          .field {
            &__label {
              font-size: $smaller;
            }

            &__items {
              font-size: $small;
            }
          }
        }
      }
    }

    &--public-workshops {

      &--full {

        .group-right-wrapper {
          .field {
            &__label {
              font-size: $slight;
            }
          }
        }

        .field-collection-view {

          .field--name-field-collection-title {
            font-size: $small;
          }

          .field--name-field-collection-body,
          .field--name-field-body {
            font-size: $small;
          }
        }

        .field--name-field-intro-text {
          font-size: $ptext2;
        }

        .like-this {
          font-size: $slight;
        }

        .custom-title {
          font-size: $super;
        }

        .field--name-field-date {
          font-size: $slight;
        }

        .group-pricing-wrapper {
          legend {
            font-size: $slight;
          }
        }

        .group-image-wrapper {

          .group-image-align {
            font-size: $newsletter;
          }
        }

        &.node-archived-workshop {

          .custom-title {
            font-size: $super;
          }
        }
      }

      &--box {

        .field--name-field-date {
          .field__label {
            font-size: $smaller;
          }

          .field__items {
            font-size: $small;
          }
        }

        .custom-title {
          font-size: $newsletter;
        }
      }

      &--teaser {

        .custom-title {
          font-size: $pwork;
        }

        .field--name-field-intro-text {
          font-size: $ptext2;
        }

        .group-info {

          .field {
            &__label {
              font-size: $smaller;
            }

            &__items {
              font-size: $small;
            }
          }
        }
      }

      &--minimal {

        .group-info {

          .field {

            &__label {
              font-size: $smaller;
              position: relative;
              top: 2px;
            }

            &__items {
              font-size: $small;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .custom-title {
          font-size: $box;
        }
      }
    }
  }

  // from components scss
  .external-associate {
    font-size: $smaller;
  }

  /*  .custom-title-box {
      .title-text {
        line-height: 1.25;
        letter-spacing: 0.2em;
        font-size: $larger;
      }
    }*/

  .activew-circle {
    font-size: $smallest;
  }

  .close-md {
    font-size: $small;
  }

  .metrics-wrapper {
    @include clearfix;

    .metrics {
      &-extra {
        font-size: $giga;
      }

      &-label {
        font-size: $smallest;
      }

      &-animate {
        font-size: $giga;
        line-height: 1.1;
      }
    }
  }

  .webform-client-form,
  .user-login {

    label {
      font-size: $smallest;
    }

    input,
    textarea,
    select {
      font-size: $small;
      line-height: 1.13;

      &[type="submit"] {
        font-size: $smaller;

        &:hover,
        &:focus {
          cursor: pointer;
        }
      }
    }

    textarea {
      line-height: 1.4;
    }

    .button {
      a {
        font-size: $smaller;
        min-width: 214px;
      }
    }

    .vertical-number {
      .yellow-title {
        font-size: $slight;
      }
    }

    .field--name-field-early-bird-price,
    .field--name-field-standard-price {

      .activated-price {
        font-size: $smaller;
        width: 214px;
      }

      .deactivated-price {
        font-size: $smaller;
        width: 214px;
      }
    }
  }

  //efs --- end font sizes

/*  html {
    font-size: 16px;
    line-height: 1.5;
  }*/

  .node {
    &--case-studies {
      &--teaser {
        text-align: center;

        .custom-title {
          margin-bottom: 30px;
        }

        .read-more {
          display: none;
        }

        .read-more.mobe {
          display: block;
          margin-top: 30px;
        }

        .group-left-wrapper-cs {
          width: 100%;
          margin: 0 auto;
          float: none;
          width: span(6 of 10);
        }

        .group-right-wrapper {
          width: 100%;
          margin-left: 0;
          float: none;
        }
      }
    }
  }

  // got2
  .node--trainer--teaser {
    .node__content {
      height: 490px;
    }
  }


  .pgwloaderModal {
    img {
      max-width: 140px;
    }
  }


  .drup-msgs {
    .messages{
      width: span(10 of 10);
    }
  }

  .l-page {
    padding-top: 76px;
  }

  .notbar-removed {
    .l-page {
      padding-top: 51px;
    }
  }

  .logged-in .l-page {
    padding-top: 47px;
  }

  .block--gtweaks-front-main-banner {
    .block__content {
      max-height: calc(100vh - 76px);
    }
  }

  .block--gtweaks-why-ux {
    .vertical-number {
      padding-left: 0;
    }

    .view-content {
      width: span(10);
      float: none;
      margin: 0 auto;
    }

    .why-ux-text {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .block {
    &--gtweaks-what-we-do {
      .block__content {
        .view-content {
          width: span(10);
          float: none;
          margin: 0 auto;
        }
      }
    }
  }

  .node--full.nnode-32
  .field-collection-view .field-collection-item-field-collection-advanced-text
  .field--name-field-methods-techniques .field--name-field-collection-title {
    margin-bottom: 0;
  }

  .nnode-36 {
      .selectboxit-options {
        /* Set's the drop down options width to the same width as the drop down button */
        max-width: 380px;
      }
    }

  #webform_drawer-36 {
    max-width: 540px;
  }

  #webform_drawer-2 {
    max-width: 540px;
  }

  .nnode-1 {

    .webform-call-us {
      margin-top: -114px;
    }

    .field--name-body {
   //   height: 384px;
    }

    .webform-client-form-1 {
   //   height: 384px;
    }
  }

  .mobile-only {
    display: block;
  }

  .node--trainer--minimal {
    .group-image {
      .custom-title {
        font-size: $normal;
      }
    }
  }

  .node--webinars--full,
  .node--in-house-training--full,
  .node--public-workshops--full {
    .field--name-field-map {
      div.map-wrap {
        &-1 {
          height: 370px;
        }

        &-2 {
          //  top: 22%;
          width: span(9 of 10);

          div[id*="styled-google-map"] {
            height: 330px !important;
          }
        }

        &-fields {
          width: span(8 of 10);
          margin: 0 auto;
        }
      }
    }

     .field-collection-view {
       margin-bottom: 10px;
     }

    .group-image-wrapper {
      .field--name-field-image {
        min-height: 480px;
      }
    }
  }

 
  .node--webinars--full,
  .node--in-house-training--full,
  .node--public-workshops--full {
    width: 100%;

   > .node__content {
      padding-top: 60px;

    }

    .group-image-wrapper {
      .group-image-align {
        width: span(10);
      }
    }

    .group-meta {
      width: span(8);
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 40px;
    }

    .node__content {
      width: 100%;
    }

    .group-left-wrapper {
      width: 100%;
      margin-right: 0;
      float: none;
      display: none;
    }

    .group-right-wrapper {
      margin-left: 0;
      float: none;
      width: 100%;
    }

    .group-content-bounds {
      width: span(10);
      margin: 0 auto;
    }
  }

  .node--in-house-training--full {
    .group-right-wrapper {
      width: 83%;
      margin: 0 auto;
    } 
  }

  .view-training.view-display-id-block_1 {
    .views-row {
      margin-bottom: 60px;
    }
  }

  .node--in-house-training--teaser {
    .group-info {
      margin: 20px 0 40px;
    }

    .group-right-wrapper {
      width: 100%;
      float: none;
      text-align: left;
      padding-top: 0;
    }

    .group-left-wrapper {
      width: 100%;
      float: none;
      margin-right: 0;
    }
  }

  .node--full {
    &.nnode-6
    .field--name-body {
      margin-left: 0;
      width: 100%;
      margin-bottom: 60px;
    }
  }

  .view-training.view-display-id-block_1 {
    .view-content {
      padding-top: 60px;
      width: span(10);
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .node--public-workshops--teaser .field--name-field-image {
    min-height: 420px;
  }


  .node--in-house-training--teaser .field--name-field-image {
    min-height: 420px;
  }

  .view-training.view-display-id-block_6,
  .view-training.view-display-id-block {
    .view-empty {
      width: span(10);
      margin-right: auto;
      margin-left: auto;
      float: none;

      .grid-wrapper {
        min-height: 420px;
        width: span(6 of 10);
      }
    }
  }

  .field--name-field-early-bird-price,
  .field--name-field-standard-price {
    .activated-price {
      padding: 13px 22px;
    }

    .deactivated-price {
      padding: 13px 22px;
    }
  }


  .node--full {
      &.nnode-5 {
        .field--name-body {
          width: 100%;
          margin-right: 0;
          margin-left: 0;
          margin-bottom: 80px;
        }
      }
  }

  .node--public-workshops {
    &--box {
      .group-all-info {
        position: absolute;
        padding: 20px;
      }

      .field--name-field-image {
        min-height: 420px;
      }
    }
  }

  .block--gtweaks-public-workshops-metrics,
  .testimonials-our-work-block .view-testimonials {
    width: span(10);
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 80px;
  }

  .view-training.view-display-id-block_5,
  .view-training.view-display-id-block_4 {
    margin-bottom: 40px;

    .custom-row {
      width: span(5 of 10);
      margin-right: gutter(of 10);
      float: left;
      clear: none;
      margin-right: 0;
    }

    .views-row-odd,
    .views-row-even {
      &.views-row {
        width: span(5 of 10);
        margin-right: gutter(of 10);
        float: left;
        clear: none;

        // &:nth-child(3n+1) {
        //   clear: both;
        // }
      }

      &:nth-child(2n) {
        margin-right: 0;
        margin-bottom: gutter(of 10);
      }

      &:nth-child(2n+1) {
        clear: both;
      }

     

      &:last-child {
        margin-bottom: gutter(of 10);
      }
    }

    .view-content {
      width: span(10);
      float: none;
      margin-left: auto;
      margin-right: auto;
      padding-top: 40px;
    }
  }

  .node--public-workshops--teaser {
    .read-more {
      padding-top: 15px;
    }

    .group-left-wrapper {
      width: 100%;
      float: none;
      margin-right: 0;
    }

    .group-right-wrapper {
      width: 100%;
      float: none;
      margin-left: 0;
      text-align: left;

      .field--type-number-integer {
        display: block;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .universal-buttons-wrap {
    .applies-for {
      display: none;
    }
  }

  .view-training {

    &.view-display-id-block_4,
      &.view-display-id-block,
    &.view-training.view-display-id-block_1 {
      .view-header {
        .vertical-number {
          left: 0;
        }
      }
    }

    &.view-display-id-block {
      .view-content {
        padding-top: 40px;
        width: span(10);
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.view-display-id-block_2 {

      // scrollbar stuff
      height: calc(100vh - 248px);

      .views-row {
        padding-bottom: 60px;
        margin-bottom: 60px;
      }
    }

    .group-wrapper-outer {
      max-width: 100%;
      position: relative;
      height: calc(100vh - 76px);
      min-height: 400px;
    }

    &.view-display-id-page_1 {

      .group-wrapper {
        padding-top: 100px;

        h2 {
          margin-bottom: 60px;
        }
      }

      .group-wrapper-outer {
        max-width: 100%;
      }
    }
  }

  .view-testimonials {
    position: relative;
    margin: 0 auto;
    padding: 100px 0;
    text-align: center;

    .view-content {
      width: span(10);
    }

    .arrow-wrapper {
      &--left {
        left: 14%;
      }

      &--right {
        right: 14%;
      }
    }
  }

  .block--gtweaks-footer-lets-talk.block {
    &:after {
      width: 200px;
      height: 199px;
      transform: translate(50%,20px);
    }

    &:before {
      width: 200px;
      height: 199px;
      transform: translate(-50%,20px);
    }

    > .block__content {
      width: span(10);
      margin: 0 auto;
      padding: 80px 0;
    }
  }

  .block--views-training-block-3 {

    .group-wrapper {
      position: relative;
      float: none;
      width: span(10);
      margin-top: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-top: 70px;
      padding-bottom: 90px;
      min-height: 0;
      margin-left: 0;
      max-width: 420px;

      &-outer {
        max-width: none;
        margin: 0;
        position: static;
      }
    }

    .views-row-last {
      .group-wrapper-outer {
        margin-left: 0;
      }
    }

    .views-row,
    &-first {
        float: none;
        width: 100%;
        margin-left: 0;

        .node {
          height: 420px;
        }
    }

    .views-row-first {
      .group-wrapper {
        margin-left: 0;
      }
    }

    .vertical-number {
      margin-top: 0;
      padding-left: 0;
      width: 100%
    }

    .yellow-title {
      position: absolute;
      top: -80px;
      left: 0;
    }
  }

  .view-header {
    float: none;
    width: 100%;
  }

  .vertical-number {
    text-align: left;
    margin-right: 0;
    width: 100%;
    // width: 100%;
    // float: none;
    // margin-right: 0;
    // position: relative;
    // @include clearfix;
    // padding-left: 0;
    // margin-left: 0;

    .yellow-title {
      // transform: none;
      // font-size: $slight;
      // white-space: nowrap;
      // display: block;
      // margin-left: 0;
      // float: left;

      /*   position: absolute;
         top: 0;
         left: 0;*/

      
      .text {
        display: inline-block;
        top: auto;
        left: auto;
        transform: none;
        text-align: left;
        padding-left: 20px;
        // margin-right: 0;
        // margin-bottom: 0;
        // font-weight: 400;
        // float: right;
        // margin-left: 90px;
        // margin-top: 6px;
      }

      .number {
        display: inline-block;
        vertical-align: middle;
        
        @include breakpoint(1024px) {
          float: left;
        }
        // width: 70px;
        // height: 45px;
        // background-size: contain;
        // float: none;
      }
    }
  }

  

  .l-region--header {
    right: -20px;
  }

  .l-header {
    position: relative;
  }

  .slicknav_menu {
    display: block;
    text-align: center;

    .social-menu-mobile-wrapper {
     // padding-bottom: 100px;
    }

    .social-media-mobile {
      margin-top: 30px;
      display: inline-block;
      @include clearfix;      
    }
  }

  .activew-bubble {
    img {
      max-width: 140px;
      margin-left: 11.5rem;
    }
  }
}

@include breakpoint($sub-tablet) {

  .block {
    &--gtweaks-what-we-do {
      .block__content {
        .view-content {
          .box {
            padding: 20px;
            @include box;
            //  width: span(8 of 10);
            width: 100%;
            float: none;
            margin: 0 auto;
            text-align: left;
            margin-bottom: gutter(of 10);

            &-image {
              float: left;
              margin-right: 20px;
            }

            &-meta {
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            }

            &-align {
              @include clearfix;
              width: 370px;
              height: 148px;
              margin: 0 auto;
              //  position: absolute;
              //  left: 50%;
              //  transform: translateX(-50%);
            }

            h2 {
              margin-top: 0;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  .external-associate {
    padding: 6px 22px;
    top: 0;
    right: 0;
    min-width: 168px;
  }

  .block--views-team-block {
    .view-content {
      width: span(10);
      margin: 0 auto;
      float: none;

      .views-row {
        @include clearfix;
      }
    }
  }

  .node--trainer {
    &--teaser {

      .external-associate {
        display: none;

        &-mobile {
          display: block;
        }
      }

      .node__content {
        height: auto;
      }

      .group-social-media {
        margin-top: 20px;
      }

      .group-right-wrapper {
        top: 0;
        transform: none;
        width: 100%;
        float: none;
        padding: 50px 20px 40px;
      }

      .field--name-field-image {
        width: 100%;
        margin-right: 0;
        float: none;
        text-align: center;
        padding-top: 0;


        .field__item {
          height: auto;
          line-height: 0;

          img {
            vertical-align: bottom;
          }
        }
      }
    }
  }

  .block--gtweaks-our-mantra {
    .group-wrapper {
      width: span(10);
      margin: 0 auto;

      h2 {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        float: none;
        margin-bottom: 20px;
      }

      .group-right-wrapper {
        width: 100%;
        float: none;
      }
    }
  }

  .node--public-workshops {
    &--minimal {

      .group-left-wrapper {
        width: span(7);
        float: left;
      }

      .custom-title {
        width: span(7);
      }

      .group-right-wrapper {
        width: span(4);
        float: right;
        margin-left: span(1 wide);
        text-align: right;
      }

    }
  }

  .block--gtweaks-front-main-banner {
    .front-main-banner-wrapper {
      width: span(10);
      margin: 0 auto;
      max-width: none;
      @include breakpoint(700px) {
        width: 85%;
        br {
          display: none;
        }
      }
    }

    .block__content {
      min-height: 470px;
    }

    .subtitle {
      margin-bottom: 40px;
    }
  }

  .block--gtweaks-copyright.block {
    float: none;
    width: span(10);
    margin: 15px auto;
    text-align: center;
    height: auto;
    line-height: 1.5;

    .cp-text {
      display: inline-block;
      float: none;
      position: relative;
      top: -9px;
    }

    .social-media-icons {
      float: none;
      margin-left: 20px;
      display: inline-block;

      a {
        float: none;
        text-align: center;
        display: inline-block;
      }

      .logo-wrap {
        display: inline-block;
        float: none;
        margin-right: 26px;
      }
    }
  }

  .block--gtweaks-social-media {
    float: none;
    width: span(10);
    margin: 15px auto 0 auto;
    padding-bottom: 25px;
    text-align: center;
    height: auto;
    line-height: 1.5;

    .logo-wrap {
      float: none;
      display: inline-block;
      @include clearfix;
    }

    .neverending-logo {
      top: 17px;
    }

    .logo-wrap-txt {
      top: 8px;
    }
  }
}

@include breakpoint($sub-tablet-small) {
  .view-training.view-display-id-block_6,
  .view-training.view-display-id-block {
    .view-empty {
      .grid-wrapper {
        width: span(8 of 10);
      }
    }
  }

  .webform-confirmation {
    min-height: 430px;

    .tickbox {
      @include sprite($sprite-tickbox-mobile);
    }
  }


    .nnode-2 {
    .selectboxit-container {
      display: inline-block;
      width: 100%;

      .selectboxit-options {
        /* Set's the drop down options width to the same width as the drop down button */
        max-width: 280px;
      }

      .selectboxit {
        width: auto;
      }
    }

    .selectboxit-text {
      max-width: 90% !important;
    }

    .webform-component--your-name,
    .webform-component--select-subject {
      float: none;
      width: 100%;
      margin-right: 0;
    }
  }

  .nnode-36 {

  .selectboxit-container {
    display: inline-block;
    width: 100%;

    .selectboxit-options {
      /* Set's the drop down options width to the same width as the drop down button */
      max-width: 280px;
    }

    .selectboxit {
      width: auto;
    }
  }

    .selectboxit-text {
      max-width: 90% !important;
    }

    .webform-component--your-name {
      float: none;
      width: 100%;
      margin-left: 0;
    }

    .webform-component--select-workshop {
      float: none;
      width: 100%;
      margin-left: 0;
    }
  }

  .nnode-1 {
    .webform-client-form-1 {
      float: none;
      width: 100%;
      height: auto;
    }

    .field--name-field-body-below {
      float: none;
    }

    .webform-call-us {
      margin: 30px 0 0;
      text-align: center;
    }

    .field--name-body {
      position: relative;
      float: none;
      width: 100%;
      margin-right: 0;
      font-size: $small;
      font-weight: 400;
      line-height: 2;
      height: auto;
      margin-bottom: 30px;
    }
  }

  .node--public-workshops--teaser {
    .group-info {
      .field {
        float: none;
        margin-right: 0;

      }
    }
  }

  .view-training {
    &.view-display-id-page_1 {

      .group-placement {
        width: span(6);
      }

      .views-row-first {
        .group-placement {
          left: auto;
          padding-left: calc(20px + 8.5%);
        }
      }

      .views-row-last {
        .group-placement {
          right: auto;
          padding-left: calc(20px + 8.5%);
        }
      }

      .views-row {
        width: 100%;
        float: none;
      }

      .group-wrapper {
        text-align: left;
      }

      .group-wrapper-outer {
        min-height: 420px;
        height: calc(50vh - 78px);
      }
    }
  }

  .node--public-workshops--minimal {
    .custom-title {
      width: 100%;
      float: none;
      margin-bottom: 20px;
    }

    .group-left-wrapper {
      width: 100%;
      float: none;
      margin-bottom: 20px;
    }

    .group-right-wrapper {
      width: 100%;
      float: none;
      text-align: left;
      margin-left: 0;
    }
  }
}

@include breakpoint($mega-mobile) {

  .block--gtweaks-public-workshops-metrics {
    margin-top: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .metrics-wrapper {
    .metrics-animate,
    .metrics-extra {
      font-size: $giga * 0.6;
    }
  }

  .block--gtweaks-why-ux {
    .box-wrapper {
      .box {
        width: 100%;
        margin-right: 0;
        float: none;
      }
    }
  }

  .node--webinars--full,
  .node--in-house-training--full,
  .node--public-workshops--full {
    .group-image-wrapper {
      .field--name-field-image {
        min-height: 370px;
      }

      .group-image-align {
        font-size: $normal;
      }
    }
  }

  .node--public-workshops--box .field--name-field-image {
    min-height: 350px;
    
  }

  .node--in-house-training--teaser {

    .field--name-field-image {
      min-height: 280px;
    }

    .group-image-wrapper {
      .custom-title {
        line-height: 1.6;
        font-size: $slight;
      }

      .custom-title {
        .title-grid-wrapper {
          width: span(8 of 10);
        }
      }
    }
  }

  .node--public-workshops--teaser {
    .field--name-field-intro-text {
      font-size: $slight;
    }

    .field--name-field-image {
      min-height: 280px;
    }

    .group-image-wrapper {
      .custom-title {
        font-size: $normal;

        .title-grid-wrapper {
          width: span(8 of 10);
        }
      }
    }
  }

  .node--public-workshops--box {
    .custom-title {
      font-size: $normal;
      line-height: 1.6;
    }
  }


  .view-training.view-display-id-block_5,
  .view-training.view-display-id-block_4 {

    .custom-row {
      width: 100%;
      margin-right: gutter(of 10);
      float: left;
      clear: none;
      margin-right: 0;
    }

    .views-row-odd,
    .views-row-even {
      &.views-row {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        float: none;
        overflow: hidden;
      }

      &:nth-child(2n) {
        margin-right: 0;
        margin-bottom: 20px;
      }

      &:last-child {
        margin-bottom: 20px;
      }
    }

    .view-content {
      width: span(10);
      float: none;
      margin-left: auto;
      margin-right: auto;
      padding-top: 40px;
    }
  }

  .view-navigation-boxes {
    .views-row {
      float: none;
      width: 100%;
      margin: 0 auto gutter();

      &.custom-title-box {
        height: 280px;

        .custom-title-box .title-text {
          font-size: $newsletter;
        }
      }

      &:nth-child(2n) {
        margin-right: auto;
        margin-bottom: gutter();
      }

      &:last-child {
        float: none;
      }
    }
  }
}

@include breakpoint($sub-mobile) {

  .node--webinars--full,
  .node--in-house-training--full,
  .node--public-workshops--full {
    .field--name-field-map {

      .map-wrap {
        &-1 {
          height: 280px;
        }

        &-2 {
          //  top: 22%;
          width: span(9 of 10);

          div[id*="styled-google-map"] {
            height: 240px !important;
          }
        }
      }
    }
  }

  #mailchimp-signup-subscribe-block-theuxprodigy-newsletter-form input[type="text"] {
    color: $black;

    &::placeholder {
      color: $black;
      opacity: 1;
    }
  }

  .block--gtweaks-our-mantra {
    margin: 0 auto 60px;
  }

  .node--full {
    &.nnode-33 {

      .field--name-body {
        margin-bottom: 60px;
      }

      .field-collection-view {
        padding: 40px 0;
        border-top: 1px solid #d7d7d7;
        margin-bottom: 0;
      }

    }

    .field--name-field-collection-advanced-text {
      margin-top: 40px;
    }

    .nnode-5 {
      .field--name-body {
        padding-bottom: 40px
      }
    }
  }

  .node--advanced-page--full,
  .node--page--full {
      .field--name-body {
        font-size: $normal;
      }
    }

  .node--case-studies--teaser {
    .custom-title {
      font-size: $slight;
    }
  }

  .block--gtweaks-our-values {
    .view-content {
      padding-top: 80px;
      width: span(10);
      margin: 0 auto;

      .value-wrap {
        height: 280px;
      }

      .value-item {
        width: 100%;
        float: none;
        padding: 22px;

        &-1 {
          .value-wrap {
            &:before {
              @include sprite($sprite-value-1-mobile);
            }
          }
        }

        &-2 {
          .value-wrap {
            &:before {
              @include sprite($sprite-value-2-mobile);
              top: 20px;
            }
          }
        }

        &-3 {
          .value-wrap {
            &:before {
              @include sprite($sprite-value-3-mobile);
            }
          }
        }

        &-4 {
          .value-wrap {
            &:before {
              @include sprite($sprite-value-4-mobile);
            }
          }
        }
      }

      .value-item {
        width: 100%;
        margin-bottom: 20px;
        float: none;

        &:nth-child(2n) {
          margin-right: 0;
          margin-bottom: 20px;
          float: none;
        }
      }
    }
  }

  .block--gtweaks-copyright.block {
    .social-media-icons {
      display: none;
    }
  }

  .block--gtweaks-social-media {
    .logo-wrap {
      margin-bottom: 5px;
      display: block;

      .logo-wrap-txt {
        top: -12px;
      }

      a {
        display: inline-block;
        float: none;
      }

      .logo-inner {
        display: block;
        &:first-child {
          margin-bottom: 8px;
        }
      }
    }

    .uxprodigy-logo {
      margin-right: 0;
    }

     .neverending-logo {
       top: 0;
       max-width: 237px;
       margin: 0 auto;

       a {
         float: none;
       }
     }
  }

  .block--gtweaks-copyright.block {
    .cp-text {
      top: 0;
    }
  }


  .block--gtweaks-footer-lets-talk.block {
    &:after {
      width: 160px;
      height: 160px;
      transform: translate(50%,20px);
    }

    &:before {
      width: 160px;
      height: 160px;
      transform: translate(-50%,20px);
    }

    > .block__content {
      width: span(10);
      margin: 0 auto;
      padding: 80px 0;
    }
  }
}

@include breakpoint($mobile) {

  // switch variables
  $unipadding: 10px;

  // paddings left stuff
  .l-nots,
  .l-header,
  .l-main,
  .l-footer,
  .l-content-2,
  .drup-msgs {
    margin-left: $unipadding;
    margin-right: $unipadding;
  }

  // .block--views-training-block-3 {
  //   .node__content {
  //     // margin-left: $unipadding;
  //     // margin-right: $unipadding;
  //   }
  // }

  .slicknav_menu {
    margin: 0 -$unipadding;
    padding: 0 $unipadding;
  }

  .l-region--header {
    right: -10px;
  }

  .grid-wrapper {
    .custom-title-box {
      left: $unipadding;
    }
  }

  .block {
    &--gtweaks-what-we-do {
      .block__content {
        .view-content {

          .box {
            width: 100%;
          }
        }
      }
    }

    &--gtweaks-copyright.block {
      height: auto;
      line-height: 1.5;
    }

    &--gtweaks-social-media {
      height: auto;
      line-height: 1.5;
    }
  }

  .view-testimonials {
    position: relative;
    // width: span(10);
    margin: 0 auto;
    padding: 140px 0;
    text-align: center;

    .arrow-wrapper {
      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }
    }
  }

  // gomo

  .activew-bubble {
    text-align: right;

    img {
      margin-left: 0;
    }
  }

  .pgwloaderModal {
    img {
      max-width: 100px;
    }
  }

  .block--gtweaks-front-main-banner {
    h1 {
      margin-bottom: 25px;
      font-size: $larger;
    }

    .subtitle {
      margin-bottom: 25px;
    }
  }

  .block--views-training-block-3 {

    .group-wrapper {

      .field--name-body {
        br {
          display: none;
        }
      }

      position: relative;
      float: none;
      width: span(10);
      margin-top: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-top: 70px;
      padding-bottom: 90px;
      min-height: 0;
      margin-left: 0;
      max-width: 420px;

      &-outer {
        max-width: none;
        margin: 0;
        position: static;
      }
    }

    .views-row-last {
      .group-wrapper-outer {
        margin-left: 0;
      }
    }

    .views-row,
    &-first {
      float: none;
      width: 100%;
      margin-left: 0;

      .node {
        height: 420px;
      }
    }

    .views-row-first {
      .group-wrapper {
        margin-left: 0;
      }
    }

    .vertical-number {
      margin-top: 0;
      padding-left: 0;
    }

    .yellow-title {
      position: absolute;
      top: -80px;
      left: 0;
    }
  }

  .view-training.view-display-id-block_6,
  .view-training.view-display-id-block {
    .view-empty {

      .grid-wrapper-inner {
        .custom-title {
          font-size: $ptext2;
        }

        .arrow-go-down {
          margin: 30px auto 0 auto;
        }

        .empty-desc {
          margin-top: 20px;
          font-size: $normal;
        }
      }

      .grid-wrapper {
        min-height: 370px;
      }
    }
  }

  .close-btn-wrapper {
    margin: 50px 0 30px;
  }

  .l-nots {
    padding-bottom: 30px;
  }

  .view-training.view-display-id-block_2 {
    height: calc(100vh - 220px);
  }

  .universal-buttons-wrap {
    .group-prices-wrap {
      .field--name-field-early-bird-price,
      .field--name-field-standard-price {
        width: 50%;

        .activated-price {
          padding: 16px 8px;
          font-size: $smaller;
          width: 100%;
        }

        .deactivated-price {
          padding: 16px 8px;
          font-size: $smaller;
          width: 100%;
        }
      }
    }

    .applies-for {
      width: 100%;
    }
  }

  .node--trainer--minimal {
    .group-image {

      .group-train-min-inside {
        transform: none;
        position: static;
      }

      .group-right-wrapper-trainer {
        height: auto;
        overflow: auto;
      }

      .field--name-field-image {
        float: none;
        margin-right: 0;
        margin-bottom: 5px;
       }
    }
  }

  .node--webinars--full,
  .node--in-house-training--full,
  .node--public-workshops--full {
    .group-meta {
      width: span(10);
    }
  }

  .block--gtweaks-footer-lets-talk.block {
    .newsletter-intro {
      font-size: $ptext2;
    }
  }

  .view-case-studies.view-display-id-block {
    .views-row {
      margin-bottom: 60px;
    }
  }

  .node--in-house-training--teaser {
    .group-info {
      .field {
        float: none;
        margin-right: 0;
      }
    }
  }

  .node--case-studies--full {
    .group-meta {
      width: span(10);
    }

    .field--type-text-long,
    .field--type-text-with-summary {
      padding-bottom: 60px;
      margin-bottom: 60px;

      .field__items {
        img {
          margin: 60px auto 0;
        }
      }
    }

    .field--name-field-image {
      margin-bottom: 60px;
    }

    &:last-child {
      margin-bottom: 60px;
    }
  }

  .view-navigation-boxes {
    .views-row {
      &.custom-title-box {
        height: 320px;
      }
    }
  }

  .block--views-companies-block {
    .block__title {
      width: span(6);
      margin: 0 auto 60px auto;
    }
  }

  .view-Companies {
    //width: span(10);
    margin: 0 auto;

    .views-row {
      &.views-row-even,
      &.views-row-odd {
        clear: none;
        margin-right: span(2 wider of 10);
        float: left;
        width: span(4 of 10);

        &:nth-child(2n) {
          margin-right: 0;
          margin-bottom: span(2 wider of 10);
        }

        &:nth-child(2n+1) {
          clear: both;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .block--gtweaks-public-workshops-metrics,
  .testimonials-our-work-block .view-testimonials {
    margin-bottom: 60px;
  }

  .slicknav_menu {
    .slicknav_menutxt {
      right: 78px;
    }
  }

  .node--case-studies--minimal {
    .group-left-wrapper {
      width: 100%;
      margin: 0 0 30px 0;
      text-align: center;
      float: none;
    }

    .group-right-wrapper {
      width: 100%;
      margin-left: 0;
      float: none;
    }
  }

  .view-training {
    &.view-display-id-page_1 {

      .group-placement {
        width: span(10);
      }

      .views-row-first {
        .group-placement {
          padding-left: calc(10px + 8.5%);
        }
      }

      .views-row-last {
        .group-placement {
          padding-left: calc(10px + 8.5%);
        }
      }
    }
  }
}

@include breakpoint($mobile-sm) {

  .pgwModal .pm-body {
    padding: 0 20px;
  }

  .block {
    &--gtweaks-what-we-do {
      .block__content {
        .view-content {
          width: span(10);
          float: none;
          margin: 0 auto;

          .box {
            padding: 42px 0;
            width: 100%;
            float: none;
            margin: 0 auto;
            text-align: center;
            margin-bottom: gutter(of 10);

            &-image {
              float: none;
              margin-right: auto;
            }

            &-meta {
              position: static;
              transform: none;
            }

            &-align {
              width: auto;
              height: auto;
            }

            h2 {
              margin: gutter(of 10) 0 100px;
            }
          }
        }
      }
    }
  }

  .metrics-wrapper {
    .metrics {
      width: span(5 of 10);
      margin-right: gutter(of 10);
      margin-bottom: 20px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(2n+1) {
        clear: both;
      }

      &:nth-child(3) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include breakpoint($mobile-standard) {

  .l-page {
    padding-top: 93px;
  }

  .logged-in .l-page {
    padding-top: 73px;
  }

  .block--gtweaks-front-main-banner {
    .block__content {
      max-height: calc(100vh - 93px);
    }
  }

  .nnode-36,
  .nnode-2 {
    .form-actions {
      max-width: 360px;
    }
  }
}

@include breakpoint((max-width: 853px) and (orientation: landscape)) {
  .slicknav_menu {
    .slicknav_nav {
      @include clearfix;

      .menu {
        text-align: center;
      }

      li {
        display: inline-block;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }

      .social-media-mobile-wrapper {
        margin-top: 15px;
      }
    }
  }
}

@include breakpoint($mobile-small) {
  .view-navigation-boxes {
    .views-row {
      &.custom-title-box {
        padding: 22px 17px;

        &:after {
          right: 17px;
          bottom: 24px;
        }
      }
    }
  }
}

//Extra//

@include breakpoint(330px) {

  .block--gtweaks-social-media {
    .logo-wrap .logo-wrap-txt-ux {
      margin-right: 0;
      margin-bottom: 5px;
      display: block;
    }
  }

}


@include breakpoint(515px) {
  .block--gtweaks-footer-lets-talk.block {
    &:after {
      width: 120px;
      height: 140px;      
    }

    &:before {
      width: 120px;
      height: 140px;     
    }
  }
}

.page-node-5{
  .metrics-wrapper {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    row-gap: 30px;
    @include breakpoint(1515px) {
      grid-template-columns: repeat(3,1fr);
    }
    @include breakpoint(600px) {
      grid-template-columns: repeat(2,1fr);
    }
    @include breakpoint(400px) {
      grid-template-columns: 1fr;
    }
    .metrics {
      width: 100% ;
      @include breakpoint(560px) {
        width: 100% ;
      }
      @include breakpoint(400px) {
        align-items: center;
      }
    }
  }
}