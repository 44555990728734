// Fonts

@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Montserrat:300,300i,400,400i,700,700i');

$mont: 'Montserrat', sans-serif;
$libre: 'Libre Baskerville', serif;

// Font sizes

$smallest: 0.66666rem; // 12px
$noti: 0.72222rem; //13px
$smaller: 0.77777rem; // 14px
$small: 0.88888rem; // 16px
$normal: 1rem; // 18px
$slight: 1.11111rem; // 20px
$ptext2: 1.22222rem; // 22px
$ptext: 1.33333rem; // 24px
$mega: 2.77777rem; // 50px
$super: 2.5rem; //45px
$larger: 1.66666rem; //30px
$largest: 1.77777rem; // 32px
$pwork:  1.94444rem; // 35px
$newsletter: 1.38888rem; // 25px
$box: 1.55555rem; //28px
$kelvin: 2.5rem; // 45px
$nkelvin: 3.333rem; //60
$metrics: 4.44444rem; //80px
$giga: 5.55555rem; // 100px


$line40: 2.222rem;