$black: #100a0f;
$yellow: #fbc31e;
$white: #ffffff;
$lgray: #f6f6f6;
$mgray: #efefef;
$gray: #757575;
$divider: #ffe57c;
$bgray: #f3f3f3;
$lgray: #f9f9f9;
$dgray: #888888;
$collg: #d7d7d7;
$dprice: #c0c0c0;
$ngray: #f2f2f2;
$nngray: #d4d4d4;

// custom
$dshadow: #e5e5e5;